.app-button {
    background-color: rgba(206, 104, 73, 1) !important;
    cursor: pointer;
    outline: none;
    transition: background-color 200ms ease-in-out;
}

.app-button:disabled, .app-button:hover:disabled {
    background-color: #e0e0e0 !important;
    cursor: not-allowed;
    transition: none;
}

.app-button:hover {
    background-color: rgba(206, 104, 73, .85) !important;
}
