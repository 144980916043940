.app__input:focus,
.app__text-area:focus,
.app__select:focus {
    border-color: #ce6849;
    box-shadow: 0 1px 1px rgba(206, 104, 73, 0.075) inset,
        0 0 8px rgba(206, 104, 73, 0.6);
    outline: 0 none;
}

.app__input,
.app__text-area,
.app__select {
    font-weight: 300;
}

.transition__box-shadow {
    transition: box-shadow 250ms, border 250ms;
}
